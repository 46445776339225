"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logScriptLoad = exports.determineLoggingLevel = exports.log = exports.logMethodEndInputAndOutput = exports.logMethodEnd = exports.logMethodBegin = void 0;
var core_common_1 = require("./core-common");
function logMethodBegin(method) {
    log("Begin.", 4 /* LogType.Debug */, method, true);
}
exports.logMethodBegin = logMethodBegin;
function logMethodEnd(method, result) {
    log("End. Returning: ".concat(result !== null && result !== void 0 ? result : "n/a"), 4 /* LogType.Debug */, method, true);
}
exports.logMethodEnd = logMethodEnd;
function logMethodEndInputAndOutput(method, input, result) {
    log("End. Input: \"".concat(input, "\", returning: \"").concat(result !== null && result !== void 0 ? result : "n/a", "\""), 4 /* LogType.Debug */, method, true);
}
exports.logMethodEndInputAndOutput = logMethodEndInputAndOutput;
function log(message, logType, caller, prependWithTimestamp) {
    if (!logType) {
        // if caller didnt provide a log type for the message, assume its "info".
        logType = 3 /* LogType.Information */;
    }
    if (message) {
        var loggingLevel = determineLoggingLevel();
        if (loggingLevel >= logType) {
            if (caller) {
                message = "[ " + caller + " ] => " + message;
            }
            if (prependWithTimestamp) {
                message = "".concat((0, core_common_1.getTime)(), ": ").concat(message);
            }
            switch (loggingLevel) {
                case 4 /* LogType.Debug */:
                    console.log(message);
                    break;
                case 3 /* LogType.Information */:
                    console.info(message);
                    break;
                case 2 /* LogType.Warning */:
                    console.warn(message);
                    break;
                case 1 /* LogType.Error */:
                default:
                    console.error(message);
            }
        }
    }
}
exports.log = log;
;
function determineLoggingLevel() {
    var clientLoggingLevel = $("#LogType");
    if (clientLoggingLevel.length > 0) {
        return Number($(clientLoggingLevel[0]).val());
    }
    else {
        return 0 /* LogType.None */;
    }
}
exports.determineLoggingLevel = determineLoggingLevel;
;
function logScriptLoad(scriptId) {
    if (scriptId) {
        console.log("Loaded script: " + scriptId);
    }
}
exports.logScriptLoad = logScriptLoad;
;
