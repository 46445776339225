"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadMoreControl = void 0;
var ReadMoreControl = /** @class */ (function () {
    function ReadMoreControl() {
        $('body').on('click', '[js-read-more]', this.onToggle.bind(this));
    }
    ReadMoreControl.prototype.onToggle = function (e) {
        var readMoreText = 'Read more';
        var readLessText = 'Read less';
        var switchElement = $(e.currentTarget);
        var targetId = switchElement.attr('aria-controls');
        if (switchElement.text().trim() == readMoreText) {
            switchElement.text(readLessText);
        }
        else {
            switchElement.text(readMoreText);
        }
        $("#".concat(targetId)).toggleClass("readMoreCover");
    };
    return ReadMoreControl;
}());
exports.ReadMoreControl = ReadMoreControl;
