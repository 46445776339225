"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertControl = void 0;
var AlertControl = /** @class */ (function () {
    function AlertControl() {
        var _this = this;
        this.closeButton = $('.alertClose');
        this.closeTarget = $('.alertArea');
        this.headerLine = $('header .contentHolder');
        this.closeButton.on('click', function (e) {
            _this.closeTarget.hide();
        });
    }
    return AlertControl;
}());
exports.AlertControl = AlertControl;
